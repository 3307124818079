<template>
<v-form @submit="submitEmail" onSubmit="return false;">
    <template v-if="!isRedirect">
        <v-text-field
            label="Email"
            v-model="editableEmail"
            :color="primaryColor"
            ref="emailInput"
            :readonly="!allowSwitchUser"
            :disabled="!allowSwitchUser"
            outlined
        >
            <template #prepend-inner>
                <font-awesome-icon :icon="['fas', 'envelope']" fixed-width class="mt-1"></font-awesome-icon>
            </template>
            <template #append-outer>
                <v-btn elevation="4" :style="primaryButtonStyle" @click="submitEmail" :disabled="!isEmailFormComplete">
                    Next
                </v-btn>
            </template>
        </v-text-field>
        <v-btn text class="px-0" :color="primaryColor" small @click="forgotEmail" v-if="allowSwitchUser">Forgot email</v-btn>

        <v-alert type="error" color="red" outlined class="mt-8" v-if="forbiddenError">
            <p class="ma-0 pa-0">The login request has expired.</p>
            <p class="mx-0 mb-0 mt-2 pa-0 grey--text text--darken-1 font-weight-light" v-if="realmWebsiteURL">
                Return to <a :href="realmWebsiteURL">{{ realmDisplayName }}</a> to start over with the login request.
            </p>
            <p class="mx-0 mb-0 mt-2 pa-0 grey--text text--darken-1 font-weight-light" v-if="!realmWebsiteURL">
                Return to the application to start over with the login request.
            </p>
        </v-alert>
        <v-alert type="error" color="red" outlined class="mt-8" v-if="emailInvalidError">
            <p class="ma-0 pa-0">The email address is invalid.</p>
            <p class="mx-0 mb-0 mt-2 pa-0 grey--text text--darken-1 font-weight-light" v-if="allowSwitchUser">Please check it carefully and try again.</p>
        </v-alert>
        <v-alert type="info" color="blue" outlined class="mt-8" v-if="emailNotFoundError">
            <p class="ma-0 pa-0">You are not registered with that email address.</p>
            <p class="ma-0 pa-0" v-if="realmSignupURL"><a :href="realmSignupURL">Sign up</a></p>
            <!-- <p class="mx-0 mb-0 mt-2 pa-0 grey--text text--darken-1 font-weight-light" v-if="allowSwitchUser">If the email address is correct, try returning to the website and starting over.</p> -->
        </v-alert>
        <v-alert type="error" color="red" outlined class="mt-8" v-if="emailError">
            <p class="ma-0 pa-0">The server has rejected this request.</p>
            <p class="mx-0 mb-0 mt-2 pa-0 grey--text text--darken-1 font-weight-light" v-if="allowSwitchUser">If the email address is correct, try returning to the website and starting over.</p>
        </v-alert>
        <v-alert type="error" color="red" outlined class="mt-8" v-if="serverError">
            <p class="ma-0 pa-0">Service unavailable</p>
            <p class="mx-0 mb-0 mt-2 pa-0 grey--text text--darken-1 font-weight-light">
                Please try again later or return to the previous page and try again.
            </p>
            <!-- <p class="mx-0 mb-0 mt-2 pa-0 grey--text text--darken-1 font-weight-light" v-if="realmWebsiteURL">
                Return to <a :href="realmWebsiteURL">{{ realmDisplayName }}</a> to start over with the login request.
            </p>
            <p class="mx-0 mb-0 mt-2 pa-0 grey--text text--darken-1 font-weight-light" v-if="!realmWebsiteURL">
                Return to the application to start over with the login request.
            </p> -->
        </v-alert>
        <!-- <v-alert type="info" color="blue" outlined class="mt-8" v-if="displayForgotEmailHint">
            <p class="ma-0 pa-0">This is the email address that you signed up with.</p>
        </v-alert> -->
    </template>
    <template v-if="isRedirect">
        <p>Redirecting...</p>
        <v-btn elevation="4" :style="primaryButtonStyle" :href="redirect">
            Continue
        </v-btn>
    </template>
</v-form>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
// import { toMillis } from '@libertyio/time-util-js';
import { INTENT_REDIRECT, RESPONSE_TYPE_FAULT, RESPONSE_TYPE_INTENT, RESPONSE_TYPE_STATUS, FAULT_INVALID_EMAIL, FAULT_NOT_REGISTERED, RECOVERY_STATUS_UNLOCKED /* , INTENT_LOGIN, INTENT_SETUP */ } from '@/sdk/loginfront/login_api_constants.js'; // '@loginfront/login-api-constants-js';
import { isValidEmail } from '@/sdk/input';

export default {
    props: ['id', 'params', 'display', 'etag'],
    data: () => ({
        editableEmail: null,
        emailError: false,
        emailInvalidError: false,
        emailNotFoundError: false,
        serverError: false,
        didAutoSubmit: false,
        submitTimestamp: null,
        redirect: null,
    }),

    computed: {
        ...mapState({
            isAuthenticatedReady: (state) => state.isReady,
            session: (state) => state.session,
            account: (state) => state.account,
            realmInfo: (state) => state.realmInfo,
            palette: (state) => state.palette,
        }),
        ...mapGetters({
            isLoading: 'isLoading',
            brandName: 'brandName',
            primaryColor: 'primaryColor',
            primaryTextColor: 'primaryTextColor',
            accentColor: 'accentColor',
            cardTitleBarTextStyle: 'cardTitleBarTextStyle',
            cardTitleBarStyle: 'cardTitleBarStyle',
            primaryButtonStyle: 'primaryButtonStyle',
            primaryIconStyle: 'primaryIconStyle',
        }),
        allowSwitchUser() {
            return this.params?.allow_switch_user ?? false;
        },
        isEmailFormComplete() {
            return typeof this.editableEmail === 'string' && this.editableEmail.length > 0 && isValidEmail(this.editableEmail);
        },
        isError() {
            return this.emailInvalidError || this.emailNotFoundError || this.emailError;
        },
        isRedirect() {
            return typeof this.redirect === 'string' && this.redirect.length > 0;
        },
    },

    watch: {
        etag(newValue) {
            console.log(`RecoveryInputEmailForm etag changed: ${newValue}`);
            this.resetView(this.params?.email);
        },
        display(newValue) {
            console.log(`RecoveryInputEmailForm display changed: ${newValue}`);
            if (newValue) {
                this.resetView(this.params?.email);
            }
        },
        focus() {
            if (this.display && this.allowSwitchUser) {
                this.$activateInput('emailInput');
            }
        },
    },

    methods: {
        resetView(email = '') {
            this.editableEmail = email;
            this.redirect = null;
            this.emailError = false;
            this.emailInvalidError = false;
            this.emailNotFoundError = false;
            this.serverError = false;
            // this.displayForgotEmailHint = false;
            if (this.display && this.allowSwitchUser) {
                this.$activateInput('emailInput');
                // this.$refs.emailInput.reset(); // don't reset the input because it would set the value to null and then auto-submit (below) will not work
            }
            if (email && this.display && !this.didAutoSubmit) {
                this.didAutoSubmit = true;
                this.submitEmail();
            }
        },
        continueWithSelectedMethod() {
            this.$bus.$emit('snackbar', { type: 'info', headline: 'Please contact customer support' });
        },
        // TODO: // @email="onEmailResult" @forgotEmail
        /**
         * Submits the email address to the server. The following things can happen:
         * * 400 Bad request, if login request id is missing; handled by catch block
         * * 403 Forbidden, if this.$route.params.realm is missing or server doesn't recognize it; handled by catch block
         * * 403 Forbidden, if the login request id is invalid
         * * 200 OK with type: fault and fault: 'invalid_email', if our email validation doesn't match the server and we submitted an invalid email address
         * * 200 OK with type: fault and fault: 'not_registered', if email is verified but no user is registered with it
         * * 200 OK with type: intent and intent: 'redirect' and redirect, if email is valid but not verified in this browser (redirect will be to Cryptium ID for email verification)
         * * 200 OK with type: intent and intent: 'login' and next (an authentication item), if email is verified, there's a registered user, and further authentication is required
         */
        async submitEmail() {
            try {
                if (Number.isInteger(this.submitTimestamp) && this.submitTimestamp + 500 > Date.now()) {
                    return;
                }
                console.log(`RecoveryInputEmailForm submitEmail: editableEmail ${this.editableEmail}`);
                if (!this.isEmailFormComplete) {
                    console.log(`RecoveryInputEmailForm submitEmail: form incomplete, email: ${JSON.stringify(this.editableEmail)}`);
                    return;
                }
                this.submitTimestamp = Date.now();
                this.emailError = false;
                this.emailInvalidError = false;
                this.emailNotFoundError = false;
                this.serverError = false;
                this.$store.commit('loading', { loginSubmitEmail: true });
                const response = await this.$client.realm(this.$route.params.realm).authn.recoverAccess(
                    this.id, // login request id
                    {
                        email: this.editableEmail,
                    },
                );
                console.log(`RecoveryInputEmailForm submitEmail response: ${JSON.stringify(response)}`);

                if (response.type === RESPONSE_TYPE_FAULT && response.fault) {
                    switch (response.fault) {
                    case FAULT_INVALID_EMAIL:
                        this.emailInvalidError = true;
                        break;
                    case FAULT_NOT_REGISTERED:
                        this.emailNotFoundError = true;
                        break;
                    default:
                        // TODO: show a message about an unknown error, maybe?
                        this.emailError = true;
                        console.log(`RecoveryInputEmailForm submitEmail: server responded with unknown fault: ${JSON.stringify(response.fault)}`);
                        break;
                    }
                    return;
                }

                if (response.type === RESPONSE_TYPE_INTENT && response.intent) {
                    switch (response.intent) {
                    case INTENT_REDIRECT: {
                        const { redirect } = response;
                        this.redirect = redirect;
                        if (typeof window.location.replace === 'function') {
                            window.location.replace(redirect);
                        } else {
                            window.location.href = redirect;
                        }
                        break;
                    }
                    default:
                        this.emailError = true;
                        console.log(`RecoveryInputEmailForm submitEmail: server responded with unknown intent: ${JSON.stringify(response.intent)}`);
                        break;
                    }
                    return;
                }

                if (response.type === RESPONSE_TYPE_STATUS && response.status) {
                    console.log(`RecoveryInputEmailForm submitEmail: status ${response.status}`);
                    switch (response.status) {
                    case RECOVERY_STATUS_UNLOCKED: {
                        this.$emit('unlocked', { authn: response.authn });
                        break;
                    }
                    default:
                        this.emailError = true;
                        console.log(`submitEmail: server responded with unknown fault: ${JSON.stringify(response.fault)}`);
                        break;
                    }
                    return;
                }

                console.error(`RecoveryInputEmailForm submitEmail: unexpected response from server: ${JSON.stringify(response)}`);
                this.emailError = true;
            } catch (err) {
                if (err.response?.status >= 400 && err.response?.status < 500) {
                    console.error('submitEmail client error', err);
                    if (err.response.status === 403) {
                        // forbidden might mean the login request expired or is invalid, and we need to return to main website to try again
                        this.forbiddenError = true;
                    } else {
                        this.emailError = true; // TODO: do more specific errors for 401 unauthorized, 409 conflict, etc. to show better error messages?
                    }
                } else if (err.response?.status >= 500) {
                    console.error('submitEmail server error', err);
                    this.serverError = true;
                    // this.$bus.$emit('snackbar', { type: 'error', headline: 'Server error', message: 'Please try again later', duration: toMillis({ seconds: 10 }) });
                } else {
                    console.error('submitEmail unexpected error', err);
                    this.emailError = true; // TODO: rename to unknown error?
                }
            } finally {
                this.$store.commit('loading', { loginSubmitEmail: false });
            }
        },
    },

    mounted() {
        /**
         * NOTE: The parent may mount this component but hide it, and in that case trying to focus on
         * the email input textfield won't work. For this reason, when the parent is ready to show
         * this component, it should also update the `display` property to true, and if the email parameter
         * changes it should update the `etag` property to the current time to trigger
         * initializing the view.
         */
        this.resetView(this.params?.email);
    },

};
</script>
