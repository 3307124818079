<template>
    <v-row no-gutters class="ma-0 pa-0" justify="center" style="height: 100%;">
        <v-col cols="12" class="ma-0 pa-0" style="height: 100%;">
        <v-row justify="center">
            <!-- TODO: realm name and logo? -->
            <!-- <h1 v-show="loginUsernameInput" class="text-h4 font-weight-light">Login</h1>
            <h1 v-show="loginWithLoginShield && !isActivatingLoginShield" class="text-h4 font-weight-light">Login</h1>
            <h1 v-show="loginWithLoginShield && isActivatingLoginShield" class="text-h4 font-weight-light">Account</h1> -->
            <!-- <h1 class="text-h4 font-weight-light" v-show="isActivatingLoginShield">Account</h1>
            <h1 class="text-h4 font-weight-light" v-show="!isActivatingLoginShield">Login</h1> -->
        </v-row>
            <template v-if="isViewReady">
                <v-row justify="center" class="py-5">
                    <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">

        <template v-if="!recoveryRequestId">
        <v-row justify="center" class="py-5 mt-8">
            <v-col>
                 <h1 class="text-h4 font-weight-light text-center">You need a recovery link to continue</h1>
                 <p class="text-center mt-10">If you arrived here while trying to log in, please go back and contact the site administrator.</p>
                 <p class="text-center mt-10"><router-link :to="{ name: 'realm-login', params: { realm: this.$route.params.realm } }">Login</router-link></p>
            </v-col>
        </v-row>
        </template>

        <template v-if="startError">
        <v-row justify="center" class="py-5 mt-8">
            <v-col>
                 <h1 class="text-h4 font-weight-light text-center">Access recovery error</h1>
                 <p class="text-center mt-10">If you arrived here while trying to log in, please go back and contact the site administrator.</p>
                 <p class="text-center mt-10"><router-link :to="{ name: 'realm-login', params: { realm: this.$route.params.realm } }">Login</router-link></p>
            </v-col>
        </v-row>
        </template>

                <template v-if="!isError">
                    <v-row class="pa-0 ma-0" justify="center">
                        <v-col>
                            <!-- The pseudonym or email with the "switch user" button must ONLY be displayed if
                            the pseudonym or email actually exists and we have an authentication workflow.
                            Invalid or non-existent pseudonym or email can still be edited via the appropriate
                            component. So the user should see EITHER a "switch user" component and an authentication
                            step or error message, OR a pseudonym or email input and NO "switch user" component.
                            It would not make sense to show a "switch user" component at the same we show a
                            pseudonym or email input component. -->
                            <template v-if="pseudonym">
                                <p class="text-center">{{ pseudonym }}</p>
                            </template>
                            <template v-if="email">
                                <p class="text-center">{{ email }}</p>
                            </template>
                            <template v-if="displaySwitchUser">
                                <v-form class="text-center">
                                    <v-btn text small :color="primaryColor" @click="switchProfile()">Change account</v-btn> <!-- previuosly color="blue darken-2" -->
                                </v-form>
                            </template>
                        </v-col>
                    </v-row>
                    <v-card elevation="4" class="pa-0 mt-5">
                        <v-app-bar :style="cardTitleBarStyle" flat>
                            <v-toolbar-title :style="cardTitleBarTextStyle">Let's recover your {{ recoveringAuthenticationStepName }}</v-toolbar-title>
                            <v-progress-linear
                                :active="isLoading"
                                :indeterminate="isLoading"
                                absolute
                                bottom
                                :color="accentColor"
                            ></v-progress-linear>
                        </v-app-bar>
                        <v-row no-gutters class="px-4 pb-4 pt-8">
                            <v-col>

                            <!-- Access recovery steps are displayed while the status is new or pending -->

                            <template v-if="displayPseudonymForm">
                                <!-- :allowSwitchUser="allowSwitchUser" -->
                                <RecoveryInputPseudonymForm :id="recoveryRequestId" :display="displayPseudonymForm" :etag="etag" @pseudonym="onPseudonymResult" @forgotPseudonym="startAccessRecovery" @unlocked="unlocked"></RecoveryInputPseudonymForm>
                            </template>

                            <template v-if="displayEmailForm">
                                <!-- :allowSwitchUser="allowSwitchUser" -->
                                <RecoveryInputEmailForm :id="recoveryRequestId" :params="nextParams" :display="displayEmailForm" :etag="etag" @email="onEmailResult" @forgotEmail="startAccessRecovery" @unlocked="unlocked"></RecoveryInputEmailForm>
                            </template>

                            <!-- <template v-if="displayPasswordForm">
                                <RecoverInputPasswordForm :id="recoveryRequestId" :display="displayPasswordForm" :etag="etag" @password="onPasswordResult" @forgotPassword="startAccessRecovery"></RecoverPasswordForm>
                            </template> -->

                            <!-- <template v-if="displayLoginShieldForm">
                                <RecoverLoginShieldForm :id="recoveryRequestId" :display="displayLoginShieldForm" :etag="etag" @loginshield="onLoginShieldResult" @newAuthenticator="startAccessRecovery"></RecoverLoginShieldForm>
                            </template> -->

                            <!-- View or reset steps are displayed while the status is unlocked -->
                            <template v-if="displayResetPasswordForm">
                                <!-- :allowSwitchUser="allowSwitchUser" -->
                                <RecoveryResetPasswordForm :id="recoveryRequestId" :display="displayResetPasswordForm" :etag="etag" @changed="recoveredPassword" @cancelled="cancelAccessRecovery"></RecoveryResetPasswordForm>
                            </template>

                            </v-col>
                        </v-row>
                    </v-card>
                </template>
        <!--
        <template v-if="status === RESET_PASSWORD">
        <v-row justify="center" class="py-5 mt-8">
            <v-col cols="12" sm="10" lg="8" md="8" xl="6">
                <v-card elevation="4" class="ma-0 pa-0">
                    <v-toolbar short flat color="white">
                        < ! - - <v-btn text class="px-0" :color="primaryColor" small @click="resetLoginForm">
                            <font-awesome-icon :icon="['fas', 'angle-left']" style="font-size: 16px;" fixed-width/>
                            Back
                        </v-btn> - - >
                        <v-toolbar-title>Reset password</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                    <p>Identity verification is finished. Reset your password to continue.</p>
                    <p class="text-overline mb-0 mt-10">Username</p>
                    <p><span>{{ userAlias }}</span></p>
                    <p class="text-overline mb-0 mt-10">Password</p>
                    <p class="mb-0 pb-0">
                        <v-btn outlined color="indigo" @click="dialogChangePassword = true">Change password</v-btn>
                    </p>
                    </v-card-text>
                    <DialogChangePassword v-model="dialogChangePassword" :token="recoveryRequestId" @changed="recoverAccess"/>
                </v-card>
            </v-col>
        </v-row>
        </template>

        <template v-if="status === RESET_LOGINSHIELD">
        <v-row justify="center" v-show="loginWithLoginShield" class="ma-0 pt-5" style="width: 100%;">
            <div id="loginshield-content" style="width: 100%; height: 600px;"></div>
        </v-row>
        </template>

        <template v-if="status === LOGIN">
        <v-row justify="center" class="py-5 mt-8">
            <v-col cols="12" sm="10" lg="8" md="6" xl="4">
                <v-card elevation="4" class="ma-0 pa-0">
                    <v-toolbar short flat color="white">
                        <v-toolbar-title>Access recovery complete!</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                    < ! - - TODO: if we had a login token from a client app that started the login request... we need to retrieve it now , or generate a new one , and let the user login and then return to the client app they came from ; if there's no token, then we can just send them to dashboard (login page will do this) - - >
                    <p>Ready to login?</p>
                    <p class="mb-0 pb-0">
                        < ! - - TODO: actually if the status is login, then user is already authenticated... we can just take them to dashboard - - >
                        <v-btn outlined color="indigo" @click="login">Login</v-btn>
                    </p>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        </template>
        -->
                    </v-col>
                </v-row>
            </template>
        </v-col>
    </v-row>
</template>

<style lang="css">
.v-input__append-outer button {
    /* position: absolute; */
    top: -8px;
}
</style>

<script>
import { mapState, mapGetters } from 'vuex';
import { toMillis } from '@libertyio/time-util-js';
// import { loginshieldInit } from '@loginshield/realm-client-browser';
// import DialogChangePassword from '@/components/DialogChangePassword.vue';
import { AUTHN_PSEUDONYM, AUTHN_EMAIL, /* AUTHN_CRYPTIUM_ID, */ AUTHN_PASSWORD, AUTHN_LOGINSHIELD, AUTHN_OTP, AUTHN_2FA, INTENT_LOGIN, INTENT_SETUP, INTENT_RECOVERY, INTENT_REDIRECT, RECOVERY_STATUS_NEW, RECOVERY_STATUS_PENDING, RECOVERY_STATUS_UNLOCKED, RECOVERY_STATUS_RECOVERED, RECOVERY_STATUS_CANCELLED, RESPONSE_TYPE_STATUS } from '@/sdk/loginfront/login_api_constants.js'; // '@loginfront/login-api-constants-js';
import RecoveryInputEmailForm from '@/components/recovery/RecoveryInputEmailForm.vue';
import RecoveryResetPasswordForm from '@/components/recovery/RecoveryResetPasswordForm.vue';

export default {
    components: {
        // DialogChangePassword,
        RecoveryInputEmailForm,
        RecoveryResetPasswordForm,
    },
    data: () => ({
        recoveryRequestId: null,
        recoveryAuthnStep: null, // the authentication step being recovered
        next: null, // then next access recovery step to display
        nextParams: null, // parameters for the next access recovery step
        isUnlocked: false, // false while the access recovery status is new or pending, true when the status is unlocked
        isViewReady: false,
        isError: false,
        redirect: null,
        /*
        // recoveryToken: null,
        userAlias: null,
        status: null,
        dialogChangePassword: false,
        loginToken: null,
        // intent: null,
        // TODO /////////////////////////////
        tokenError: false,
        from: null,
        isActivatingLoginShield: false,
        loginUsernameInput: true,
        loginPasswordInput: false,
        loginWithLoginShield: false,
        recoverAccessInput: false,
        password: null,
        email: null,
        isUsernameEditable: true,
        startError: false,
        userAliasError: false,
        passwordError: false,
        recoverAccessError: false,
        loginshieldStartError: false,
        isRememberMeChecked: null,
        // email verification
        isEmailSent: false,
        linkExpires: null,
        dialogAccessRecoveryHelp: false,
        */
    }),

    computed: {
        /*
        RESET_PASSWORD() {
            return 'reset-password';
        },
        RESET_LOGINSHIELD() {
            return 'reset-loginshield';
        },
        LOGIN() {
            return 'login';
        },
        // TODO /////////////
        */
        ...mapState({
            isReady: (state) => state.isReady,
            session: (state) => state.session,
            account: (state) => state.account,
            realmInfo: (state) => state.realmInfo,
            focus: (state) => state.focus,
        }),
        ...mapGetters({
            isLoading: 'isLoading',
            brandName: 'brandName',
            primaryColor: 'primaryColor',
            primaryTextColor: 'primaryTextColor',
            accentColor: 'accentColor',
            cardTitleBarTextStyle: 'cardTitleBarTextStyle',
            cardTitleBarStyle: 'cardTitleBarStyle',
            primaryButtonStyle: 'primaryButtonStyle',
            primaryIconStyle: 'primaryIconStyle',
        }),
        /*
        isAuthenticated() {
            return this.session.isAuthenticated;
        },
        isUsernameFormComplete() {
            return typeof this.userAlias === 'string' && this.userAlias.length > 0;
        },
        isPasswordFormComplete() {
            return typeof this.password === 'string' && this.password.length > 0;
        },
        isRecoverAccessFormComplete() {
            return typeof this.email === 'string' && this.email.length > 0;
        },
        */
        displayPseudonymForm() {
            return this.next === AUTHN_PSEUDONYM;
        },
        displayEmailForm() {
            return this.next === AUTHN_EMAIL;
        },
        displayPasswordForm() {
            return this.next === AUTHN_PASSWORD;
        },
        displayLoginShieldForm() {
            return this.next === AUTHN_LOGINSHIELD;
        },
        displayOtpForm() {
            return this.next === AUTHN_OTP;
        },
        display2faForm() {
            return this.next === AUTHN_2FA;
        },
        displayViewAliasPseudonymForm() {
            return this.isUnlocked && this.recoveryAuthnStep === AUTHN_PSEUDONYM;
        },
        displayViewAliasEmailForm() {
            return this.isUnlocked && this.recoveryAuthnStep === AUTHN_EMAIL;
        },
        displayResetPasswordForm() {
            return this.isUnlocked && this.recoveryAuthnStep === AUTHN_PASSWORD;
        },
        displayResetLoginShieldForm() {
            return this.isUnlocked && this.recoveryAuthnStep === AUTHN_LOGINSHIELD;
        },
        displayResetOtpForm() {
            return this.isUnlocked && this.recoveryAuthnStep === AUTHN_OTP;
        },
        displayReset2faForm() {
            return this.isUnlocked && this.recoveryAuthnStep === AUTHN_2FA;
        },
        recoveringAuthenticationStepName() {
            let text;
            switch (this.recoveryAuthnStep) {
            case AUTHN_PSEUDONYM:
                text = 'username';
                break;
            case AUTHN_EMAIL:
                text = 'email';
                break;
            case AUTHN_PASSWORD:
                text = 'password';
                break;
            case AUTHN_LOGINSHIELD:
                text = 'LoginShield Authenticator';
                break;
            case AUTHN_OTP:
                text = 'OTP';
                break;
            case AUTHN_2FA:
                text = '2FA';
                break;
            default:
                text = this.recoveryAuthnStep;
                break;
            }
            return text;
        },
        /*
        isRecoveringPseudonym() {
            return this.recoveryAuthnStep = AUTHN_PSEUDONYM;
        },
        isRecoveringEmail() {
            return this.recoveryAuthnStep = AUTHN_EMAIL;
        },
        isRecoveringPassword() {
            return this.recoveryAuthnStep = AUTHN_PASSWORD;
        },
        isRecoveringLoginShield() {
            return this.recoveryAuthnStep = AUTHN_LOGINSHIELD;
        },
        isRecoveringOtp() {
            return this.recoveryAuthnStep = AUTHN_OTP;
        },
        isRecovering2fa() {
            return this.recoveryAuthnStep = AUTHN_2FA;
        },
        */
    },

    watch: {
        focus() {
            this.getAccessRecoveryStatus();
        },
    },

    methods: {
        resumeAfterAccessRecovery(resume) {
            // redirect to the activity indicated in the 'resume' object
            const { intent, intent_params: intentParams = {} } = resume ?? {};
            switch (intent) {
            case INTENT_LOGIN: {
                const { login_request_id: loginRequestId } = intentParams;
                this.$router.replace({ name: 'realm-login', params: { realm: this.$route.params.realm }, query: { r: loginRequestId } });
                break;
            }
            case INTENT_SETUP: {
                const { login_request_id: loginRequestId } = intentParams;
                this.$router.replace({ name: 'realm-login', params: { realm: this.$route.params.realm }, query: { r: loginRequestId } }); // TODO: should this be going to setup instead ???
                break;
            }
            case INTENT_RECOVERY: {
                // if the access recovery was stacked, resume the previous request
                const { recovery_request_id: recoveryRequestId } = intentParams;
                this.$router.replace({ name: 'realm-access-recovery', params: { realm: this.$route.params.realm }, query: { id: recoveryRequestId } });
                break;
            }
            case INTENT_REDIRECT: {
                const { redirect } = intentParams;
                this.redirect = redirect;
                if (typeof window.location.replace === 'function') {
                    window.location.replace(redirect);
                } else {
                    window.location.href = redirect;
                }
                break;
            }
            default:
                console.error(`resumeAfterAccessRecovery: unsupported resume intent: ${JSON.stringify(intent)}`);
                // this.error = true;
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Unexpected response from server', message: 'Please contact customer support', duration: toMillis({ seconds: 15 }) });
            }
        },
        async getAccessRecoveryStatus() {
            console.log(`getAccessRecoveryStatus id: ${this.recoveryRequestId}`);
            try {
                this.$store.commit('loading', { getAccessRecoveryStatus: true });
                const response = await this.$client.realm(this.$route.params.realm).authn.getRecoverAccessRequestStatus(this.recoveryRequestId);
                console.log(`getAccessRecoveryStatus response: ${JSON.stringify(response)}`);
                const { status } = response;
                console.log('getAccessRecoveryStatus UPDATE 2');
                console.log(`getAccessRecoveryStatus status ${JSON.stringify(status)} vs new ${JSON.stringify(RECOVERY_STATUS_NEW)} or pending ${JSON.stringify(RECOVERY_STATUS_PENDING)} or unlocked ${JSON.stringify(RECOVERY_STATUS_UNLOCKED)} or recovered ${JSON.stringify(RECOVERY_STATUS_RECOVERED)} or cancelled ${JSON.stringify(RECOVERY_STATUS_CANCELLED)}`);
                console.log(`getAccessRecoveryStatus toMillis ${toMillis({ minutes: 1 })}`);
                if ([RECOVERY_STATUS_RECOVERED, RECOVERY_STATUS_CANCELLED].includes(status)) {
                    const { resume } = response;
                    this.resumeAfterAccessRecovery(resume);
                    return;
                }
                if ([RECOVERY_STATUS_UNLOCKED].includes(status)) {
                    // const { resume } = response;
                    // TODO: redirect to the activity indicated in the 'resume' object
                    const { authn } = response;
                    console.log(`getAccessRecoveryStatus unlocked for authn ${JSON.stringify(authn)}`);
                    this.recoveryAuthnStep = authn;
                    this.isUnlocked = true;
                    this.next = null;
                    this.nextParams = {};
                }
                if ([RECOVERY_STATUS_NEW, RECOVERY_STATUS_PENDING].includes(status)) {
                    const { authn, next, params } = response;
                    console.log(`getAccessRecoveryStatus new or pending for authn ${JSON.stringify(authn)} with params ${JSON.stringify(params)}`);
                    this.recoveryAuthnStep = authn;
                    this.isUnlocked = false;
                    this.next = next;
                    this.nextParams = params ?? {};
                    /*
                    switch (next) {
                    case AUTHN_PSEUDONYM:
                        break;
                    case AUTHN_EMAIL:
                        break;
                    case AUTHN_PASSWORD:
                        break;
                    case AUTHN_LOGINSHIELD:
                        break;
                    case AUTHN_OTP:
                        break;
                    case AUTHN_2FA:
                        break;
                    default:
                        console.log(`getAccessRecoveryStatus: unsupported authentication step: ${JSON.stringify(next)}`);
                        this.$bus.$emit('snackbar', { type: 'error', headline: 'Error', message: 'Please contact customer support', duration: toMillis({ seconds: 10 }) });
                    }
                    */
                }
                /*
                const {
                    id,
                    alias,
                    redirect,
                    next,
                    status,
                    loginToken,
                    error,
                } = response;
                if (error) {
                    // remove token from url and show an alert that something went wrong with the recovery request
                    this.$router.replace({ name: 'realm-access-recovery', params: { realm: this.$route.params.realm } });
                    this.startError = true;
                    return;
                }
                if (redirect) {
                    console.log(`getAccessRecoveryStatus: redirect ${redirect}`);
                    window.location.href = redirect;
                    return;
                }
                if (next) {
                    console.log(`getAccessRecoveryStatus: next route ${JSON.stringify(next)}`);
                    this.$router.replace(next);
                    return;
                }
                if (id) {
                    this.recoveryRequestId = id;
                }
                if (alias) {
                    this.userAlias = alias;
                }
                if (status) {
                    this.status = status;
                }
                if (loginToken) {
                    this.loginToken = loginToken;
                }
                */
            } catch (err) {
                console.error('getAccessRecoveryStatus failed', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Error', message: 'Something went wrong' });
            } finally {
                this.$store.commit('loading', { getAccessRecoveryStatus: false });
                this.isViewReady = true;
            }
        },
        /*
        async recoverAccess() {
            console.log('recoverAccess called after changed password');
            try {
                this.$store.commit('loading', { recoverAccess: true });
                const response = await this.$client.realm(this.$route.params.realm).authn.recoverAccess({
                    // token: this.recoveryToken,
                    id: this.recoveryRequestId,
                });
                console.log(`access recovery response: ${JSON.stringify(response)}`);
                const {
                    id,
                    alias,
                    redirect,
                    next,
                    status,
                    loginToken,
                    error,
                } = response;
                if (error) {
                    // remove token from url and show an alert that something went wrong with the recovery request
                    this.$router.replace({ name: 'realm-access-recovery', params: { realm: this.$route.params.realm } });
                    this.startError = true;
                    return;
                }
                if (redirect) {
                    console.log(`recoverAccess: redirect ${redirect}`);
                    window.location.href = redirect;
                    return;
                }
                if (next) {
                    console.log(`recoverAccess: next route ${JSON.stringify(next)}`);
                    this.$router.replace(next);
                    return;
                }
                if (id) {
                    this.recoveryRequestId = id;
                }
                if (alias) {
                    this.userAlias = alias;
                }
                if (status) {
                    this.status = status;
                }
                if (loginToken) {
                    this.loginToken = loginToken;
                }
            } finally {
                this.$store.commit('loading', { recoverAccess: false });
            }
        },
        */
        /**
         * This event is emitted by access recovery input components when the server indicated the access recovery request is now unlocked
         */
        async unlocked({ authn } = {}) {
            console.log(`AccessRecovery: unlocked ${authn}`);
            // refresh session in case server has authenticated the user (this doesn't always happen -- for example if user forgot their username, when recovery is successful they have permission to see their username but they are not yet authenticated, they still have to sign in with that username)
            await this.$store.dispatch('refresh');
            if (authn !== this.recoveryAuthnStep) {
                console.warn(`Access recovery: event authn value ${JSON.stringify(authn)} does not match cached value ${JSON.stringify(this.recoveryAuthnStep)}`);
            }
            this.next = null; // hide any input form that is currently displayed
            this.isUnlocked = true;
        },
        recoveredPassword() {
            // refresh session in case server has authenticated the user
            console.log('AccessRecovery: password reset complete');
            this.getAccessRecoveryStatus();
        },
        /**
         * This is called when user taps the 'cancel' button.
         */
        async cancelAccessRecovery() {
            console.log('cancelAccessRecovery');
            try {
                this.$store.commit('loading', { cancelAccessRecovery: true });
                const response = await this.$client.realm(this.$route.params.realm).authn.recoverAccess(
                    this.recoveryRequestId,
                    {
                        action: 'cancel',
                    },
                );
                console.log(`cancelAccessRecovery response: ${JSON.stringify(response)}`);
                const { type } = response;
                if (type === RESPONSE_TYPE_STATUS) {
                    const { status } = response;
                    if ([RECOVERY_STATUS_RECOVERED, RECOVERY_STATUS_CANCELLED].includes(status)) {
                        const { resume } = response;
                        this.resumeAfterAccessRecovery(resume);
                        return;
                    }
                    // we could check for other things but we shouldn't get anything else after cancelling
                    console.error(`cancelAccessRecovery unexpected status: ${JSON.stringify(status)}`);
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Server error', message: 'Please contact customer support', duration: toMillis({ seconds: 10 }) });
                    return;
                }
                console.error(`cancelAccessRecovery unexpected response type: ${JSON.stringify(type)}`);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Server error', message: 'Please contact customer support', duration: toMillis({ seconds: 10 }) });
                return;
            } finally {
                this.$store.commit('loading', { cancelAccessRecovery: false });
            }
        },
        /*
        login() {
            this.$router.push({ name: 'realm-login', params: { realm: this.$route.params.realm }, query: { alias: this.userAlias, token: this.loginToken } });
        },
        // //////////// TODO  //////////       remove all the loginshield stuff???   we should only have login implemented once, in Login.vue
        // TODO /////////////////////////////
        resetErrors() {
            this.passwordError = false;
            this.loginshieldStartError = false;
        },
        resetLoginForm() {
            this.isEmailSent = false;
            this.linkExpires = null;
            this.loginWithLoginShield = false;
            this.loginPasswordInput = false;
            this.loginUsernameInput = true;
            this.recoverAccessInput = false;
            this.userAlias = '';
            this.password = '';
            this.userAliasError = false;
            this.passwordError = false;
            this.recoverAccessError = false;
            this.loginshieldStartError = false;
            this.$refs.userAliasField.reset();
            this.$refs.passwordField.reset();
            this.$activateInput('userAliasField');
        },
        switchProfile() {
            // this.isLoginUsernameError = false;
            // this.isLoginPasswordError = false;
            // this.loginPasswordInput = false;
            // this.loginUsernameInput = true;
            this.resetLoginForm();
        },
        async loginUsername() {
            if (!this.isUsernameFormComplete) {
                return;
            }
            // this.loginUsernameInput = false;
            // this.loginWithLoginShield = true;
            // this.startLoginShield({
            //     username: this.userAlias,
            //     interactionId: this.interactionId,
            //     nextRoute: this.next,
            //     rememberMe: true,
            // });
            console.log('Login.vue: loginUsername');
            this.passwordError = false;

            try {
                this.$store.commit('loading', { loginUsername: true });
                const {
                    // isAuthenticated,
                    // intent, // could be 'signup' in conjunction with redirect, so we could show a card that says 'you need to sign up to continue, and here's the link' and show the redirect URL as a sign up link instead of automatically redirecting the user; it's our choice whether to show that step or redirect immediately
                    status,
                    redirect,
                    mechanism,
                    // route,
                    // expires,
                    error,
                } = await this.$client.realm(this.$route.params.realm).authn.loginWithUserAlias({
                    alias: this.userAlias,
                    token: this.loginToken,
                });
                if (error) {
                    console.error(`loginUsername login error: ${JSON.stringify(error)}`);
                    this.userAliasError = true;
                    return;
                }
                // TODO: now that we're using Cryptium ID for email verification, we can also remove the following elements from the UI:  isEmailSent, linkExpires
                // if (route === 'email') {
                //     this.isEmailSent = true;
                //     this.linkExpires = expires;
                //     return;
                // }
                if (status === 'redirect' && redirect) {
                    if (redirect.startsWith('/')) {
                        this.$router.replace(redirect);
                    } else if (typeof window.location.replace === 'function') {
                        window.location.replace(redirect);
                    } else {
                        window.location.href = redirect;
                    }
                    return;
                }
                if (status === 'email-required') {
                    // TODO: xyzzy need to tell user that email is required to continue, but also we ned to store the pseudonym input so that when email input is done, or email verification is done , we can return to the same location and have the pseudonym pre-filled that the user already entered
                }
                // this.intent = intent;
                if (mechanism === 'password') {
                    this.loginUsernameInput = false;
                    this.loginPasswordInput = true;
                    this.$activateInput('passwordField');
                } else if (mechanism === 'loginshield') {
                    this.loginUsernameInput = false;
                    this.loginWithLoginShield = true;
                    this.startLoginShield({
                        username: this.userAlias,
                        interactionId: this.interactionId,
                        nextRoute: this.next,
                        rememberMe: true, // this.isRememberMeChecked
                    });
                } else {
                    this.passwordError = true;
                    this.resetLoginForm();
                }
            } catch (err) {
                console.error('loginuserAlias failed', err);
                if (err.response && err.response.status === 401 && this.loginToken) {
                    this.tokenError = true;
                    this.$router.replace({ name: 'realm-login', params: { realm: this.$route.params.realm }, query: { alias: this.$route.query.alias } });
                    this.loginToken = null;
                }
            } finally {
                this.$store.commit('loading', { loginUsername: false });
            }
        },
        async loginPassword() {
            try {
                if (!this.isPasswordFormComplete) {
                    return;
                }
                this.passwordError = false;
                const { isAuthenticated, redirect, error } = await this.$client.realm(this.$route.params.realm).authn.loginWithPassword({
                    token: this.loginToken,
                    alias: this.userAlias,
                    password: this.password,
                });
                if (redirect) {
                    window.location.href = redirect;
                    return;
                }
                if (isAuthenticated) {
                    await this.updateSession({ isAuthenticated });
                    this.redirectAfterLogin();
                } else {
                    if (error) {
                        console.error(`loginPassword error: ${error}`);
                    }
                    this.passwordError = true;
                    this.resetLoginForm();
                }
            } catch (err) {
                console.error('loginPassword failed', err);
                this.passwordError = true;
                this.resetLoginForm();
            }
        },
        forgotUsername() {
            this.loginUsernameInput = false;
            this.recoverAccessInput = true;
        },
        forgotPassword() {
            this.loginPasswordInput = false;
            this.recoverAccessInput = true;
        },
        async recoverAccessWithEmail() {
            try {
                this.$store.commit('loading', { recoverAccessWithEmail: true });
                const response = await this.$client.realm(this.$route.params.realm).authn.recoverAccess({ email: this.email });
                if (response?.route === 'email') {
                    this.isEmailSent = true;
                } else {
                    // this.$bus.$emit('snackbar', { type: 'error', message: 'Failed to start access recovery' });
                    this.recoverAccessError = true;
                }
            } catch (err) {
                console.error('failed to send access recovery email', err);
                // this.$bus.$emit('snackbar', { type: 'error', message: 'Failed to start access recovery' });
                this.recoverAccessError = true;
            } finally {
                this.$store.commit('loading', { recoverAccessWithEmail: false });
            }
        },
        onResult(result) {
            switch (result.status) {
            case 'verify':
                this.finishLoginShield({ verifyToken: result.verifyToken });
                break;
            case 'error':
                if (this.$route.query.mode === 'activate-loginshield') {
                    this.$router.push({ name: 'realm-user-preferences-authn', params: { realm: this.$route.params.realm } }); // TODO: this.redirectAfterLogin(); ?
                    return;
                }
                this.loginshieldStartError = true;
                this.resetLoginForm();
                break;
            case 'cancel':
                if (this.$route.query.mode === 'activate-loginshield') {
                    this.$router.push({ name: 'realm-user-preferences-authn', params: { realm: this.$route.params.realm } }); // TODO: this.redirectAfterLogin(); ?
                    return;
                }
                this.resetLoginForm();
                break;
            default:
                console.error(`Login.vue: onResult: unknown status ${result.status}`);
            }
        },
        async startLoginShield({
            mode, username, interactionId, nextRoute, rememberMe,
        }) {
            this.resetErrors();
            console.log(`startLoginShield: dispatch login with interactionId ${interactionId}`);
            try {
                this.$store.commit('loading', { loginWithLoginShield: true });
                // NOTE: username and mode are used to initiate a login; token is used to finish a login request
                const { error, forward, interactionId: nextInteractionId } = await this.$client.realm(this.$route.params.realm).authn.loginWithLoginShield({
                    interactionId, // e.g. create_account or require_login interaction
                    nextRoute, // a path to another view, with optional query parameters; for when we want to return the user somewhere after login but there's no interaction id
                    username,
                    mode,
                });

                console.log(`startLoginShield: response nextInteractionId ${nextInteractionId}`);
                if (nextInteractionId) {
                    this.interactionId = nextInteractionId;
                }
                if (forward) {
                    let loginMode = null;
                    if (mode === 'activate-loginshield') {
                        loginMode = 'link-device'; // disable notifications for this login request
                    }
                    // redirect to loginshield for login
                    // this works, it's the redirect method: window.location = forward;
                    loginshieldInit({
                        elementId: 'loginshield-content',
                        backgroundColor: '#efefef',
                        // width: '100%',
                        // height: '600px',
                        action: 'start',
                        mode: loginMode,
                        forward,
                        rememberMe,
                        onResult: this.onResult.bind(this),
                        // onLogin: ((verifyInfo) => {
                        //     this.finishLoginShield(verifyInfo);
                        // }),
                        // onError: ((err) => {
                        //     console.log('startLoginShield: login failed, error: %o', err);
                        //     this.loginshieldStartError = true;
                        //     this.resetLoginForm();
                        // }),
                    });
                } else {
                    // TODO: show a more specific error message that the account either doesn't exist or
                    // doesn't have loginshield enabled, or isn't ready to activate loginshield
                    if (error) {
                        console.error(`startLoginShield error: ${error}`);
                    }
                    if (this.$route.query.mode === 'activate-loginshield') {
                        this.$router.push({ name: 'realm-user-preferences-authn', params: { realm: this.$route.params.realm } }); // TODO: this.redirectAfterLogin(); ?
                        return;
                    }
                    this.loginshieldStartError = true;
                    this.resetLoginForm();
                }
            } finally {
                this.$store.commit('loading', { loginWithLoginShield: false });
            }
        },
        async resumeLoginShield({ forward, rememberMe }) {
            this.resetErrors();
            loginshieldInit({
                elementId: 'loginshield-content',
                backgroundColor: '#efefef',
                // width: '100%',
                // height: '600px',
                action: 'resume',
                forward,
                rememberMe,
                onResult: this.onResult.bind(this),
                // onLogin: ((verifyInfo) => {
                //     this.finishLoginShield(verifyInfo);
                // }),
                // onError: ((err) => {
                //     console.log('resumeLoginShield: login failed, error: %o', err);
                //     this.loginshieldStartError = true;
                //     this.resetLoginForm();
                // }),
            });
        },
        async finishLoginShield({ verifyToken }) {
            console.log(`finishLoginShield: verifying login with token: ${verifyToken} interactionId: ${this.interactionId}`);
            try {
                this.$store.commit('loading', { loginWithLoginShield: true });
                // NOTE: username and mode are used to initiate a login; token is used to finish a login request
                const { isAuthenticated, next, error } = await this.$client.realm(this.$route.params.realm).authn.loginWithLoginShield({
                    // mode: 'verify-loginshield',  mode is not even needed, if we send the token the service knows to use it to verify the login ;
                    verifyToken,
                    interactionId: this.interactionId, // e.g. points to an create-account or login interaction
                });
                await this.updateSession({ isAuthenticated });

                if (isAuthenticated) {
                    this.redirectAfterLogin({ nextInteractionId: next });
                } else if (error) {
                    console.error(`finishLoginShield error: ${error}`);
                    this.loginshieldStartError = true;
                    this.resetLoginForm();
                    // TODO: if the error is 'unauthorized' show links to login or create account (the
                    // two interactions that are available, and we don't indicate to the user which was
                    // involved in the requested interaction, since they don't have access) by redirect
                    // user to front page
                    if (error === 'unauthorized') {
                        this.$router.push({ name: 'realm-interaction', query: { error: 'unauthorized' }, params: { realm: this.$route.params.realm } });
                    }
                } else {
                    // TODO: show a loginshield specific error and then try loginshield login again,
                    // because this situation could happen when a phishing attack is circumvented,
                    // and the second login would succeed; in this case the call returns
                    // isAuthenticated: false, but no error, because the processing is routine
                    console.error('finishLoginShield not authenticated');
                    this.loginshieldStartError = true;
                    this.resetLoginForm();
                }

                console.log(`finishLoginShield: isAuthenticated ${isAuthenticated} next ${next}`);
            } finally {
                this.$store.commit('loading', { loginWithLoginShield: false });
            }
        },
        async updateSession({ isAuthenticated }) {
            this.$store.commit('setSession', { ...this.session, isAuthenticated });
            if (isAuthenticated) {
                await this.$store.dispatch('loadUser');
            } else {
                this.$store.commit('setUser', {});
            }
        },
        async redirectAfterLogin({ nextInteractionId } = {}) {
            if (nextInteractionId) {
                const nextInteraction = await this.$store.dispatch('loadInteraction', nextInteractionId);
                console.log('finishLoginShield: next interaction: %o', nextInteraction);
                if (nextInteraction && nextInteraction.type) {
                    switch (nextInteraction.type) {
                    case 'require_login':
                        this.$router.push(nextInteraction.state.redirect);
                        return;
                    default:
                        this.$router.push({ name: 'realm-dashboard', query: { i: nextInteractionId }, params: { realm: this.$route.params.realm } });
                        return;
                    }
                }
            }
            if (this.next) {
                this.$router.push(this.next);
                return;
            }
            this.$router.push({ name: 'realm-dashboard', params: { realm: this.$route.params.realm } });
        },
        */
    },

    mounted() {
        // an access recovery token is required; users obtain it by requesting access recovery
        // when they try to login, and following the email link; each time the link is followed,
        // a new access recovery request and token are generated
        // this.recoveryToken = this.$route.query.token;
        this.recoveryRequestId = this.$route.query.id;

        if (this.recoveryRequestId) {
            this.getAccessRecoveryStatus();
        } else {
            this.isError = true;
            this.isViewReady = true;
        }
    },
};
</script>
