<template>
    <ChangePasswordForm :token="passwordResetToken" :display="display" @changed="$emit('changed')" @cancelled="$emit('cancelled')">
        <template #pretext>
            <p>Choose a new password:</p>
        </template>
    </ChangePasswordForm>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import ChangePasswordForm from '@/components/ChangePasswordForm.vue';

export default {
    props: ['id', 'display', 'etag'],
    components: {
        ChangePasswordForm,
    },
    data: () => ({
    }),

    computed: {
        ...mapState({
            isAuthenticatedReady: (state) => state.isReady,
            session: (state) => state.session,
            account: (state) => state.account,
            realmInfo: (state) => state.realmInfo,
            palette: (state) => state.palette,
        }),
        ...mapGetters({
            isLoading: 'isLoading',
            brandName: 'brandName',
            primaryColor: 'primaryColor',
            primaryTextColor: 'primaryTextColor',
            accentColor: 'accentColor',
            cardTitleBarTextStyle: 'cardTitleBarTextStyle',
            cardTitleBarStyle: 'cardTitleBarStyle',
            primaryButtonStyle: 'primaryButtonStyle',
            primaryIconStyle: 'primaryIconStyle',
        }),
        passwordResetToken() {
            return this.id; // the password recovery token is simply an access recovery request id; the server will check that 1) the current session is authorized for that request, and 2) request is in unlocked status, and 3) that the request authorizes the user to reset the password
        },
    },

    watch: {
    },

    methods: {
    },

    mounted() {
    },

};
</script>
